var offersCarouselManager = (function () {
  var mainOfferCarousel = [];
  var initialMaxHeight = null;

  function init() {
    $(document).on('shown.bs.tab', 'a[data-toggle="pill"]', function (e) {
      initOfferCarousel();
    })
    if (document.readyState === "complete") {
      initOfferCarousel();
    } else {
      addEventListener("load", function () {
        initOfferCarousel();

      });
    }

    if (!$('.offer-page').length){
      $('.offer-card.offer-new, .offer-card.offer-card-compact').click(function(e){
        e.preventDefault();
        if($(e.target).data('link-url')){
          window.open($(e.target).data('link-url'), '_blank');
        }else if($(e.target).data("annotation-link")){
          return;
        } else if(!$(e.target).hasClass("favourite-icon-blue")) {
          if ($(e.target).closest('.offer-card.offer-new, .offer-card.offer-card-compact').data('link-url').startsWith("#")) {
            document.getElementById($(e.target).closest('.offer-card.offer-new, .offer-card.offer-card-compact').data('link-url').substring(1)).scrollIntoView({ behavior: 'smooth', block: 'start' })
          } else {
            if($(this).data('target-url')){
              window.open($(this).data('link-url'), $(this).data('target-url'));
            } else {
              window.location.href = $(e.target).closest('.offer-card.offer-new, .offer-card.offer-card-compact').data('link-url');
            }
          }
        }
      });
      trackOfferCardClick();
    }

    var itemsList = $('.offer-carousel-restyle .nav-pills')
    var navItems = itemsList.find('.nav-item');
    var emptyItems = navItems.find('.nav-link span').text()==='';
    if (emptyItems){
      itemsList.addClass('d-none');
    }else if (navItems.length>1){
      var maxWidth = 0;
      var maxHeight = 0;
      navItems.each(function () {
        maxWidth = ($(this).outerWidth(true) > maxWidth) ? $(this).outerWidth(true) : maxWidth;
      });
      navItems.outerWidth(maxWidth, true);
      navItems.each(function () {
        maxHeight = ($(this).outerHeight() > maxHeight)? $(this).outerHeight() : maxHeight;
      });
      navItems.outerHeight(maxHeight);
    }

    //const el = document.getElementById("offers-tab");
    //el.addEventListener("click", handleTabActiveInactiveIcon);
    // window.addEventListener("load", function() {
    //     manageShareElements();
    //
    // });
    // $(document).ready(function () {
    //
    // });
  }

  //track offer card click
  function trackOfferCardClick(){
    $(".offer-card.offer-card-compact").on("click",function(){
      var vehicleClicked = $(this).find("p.offer-title").text().replace(/\s{2,}/g,' ').trim();
      manageGTM.trackOfferCardClick(vehicleClicked);
    })
  }

  // function manageShareElements() {
  //     var isOpen = false;
  //     /*var shareCta = $();*/
  //     var shareElems = $(".share-cta-elems");
  //     /*var shareClose = $(".share-cta-elems-close");*/
  //
  //     $(document).on("click",".share-cta-elems-close", function (e) {
  //         var target = $(this).closest('.share-cta-elems');
  //         if (target.hasClass('show')) {
  //             target.removeClass('show');
  //         }
  //         e.preventDefault();
  //     });
  //
  //     $(document).on("click",".share-cta", function (e) {
  //         if (!$(this).closest('.share-container').find('.share-cta-elems').hasClass('show')) {
  //             $(this).closest('.share-container').find('.share-cta-elems').addClass('show');
  //         } else {
  //             $(this).closest('.share-container').find('.share-cta-elems').removeClass('show');
  //         }
  //         e.preventDefault();
  //     });
  //     $(document).mouseup(function (e) {
  //         if (shareElems != null && !shareElems.is(e.target) && shareElems.has(e.target).length === 0){
  //             shareElems.removeClass('show');
  //         }
  //     })
  // }

  function handleTabActiveInactiveIcon() {
    $('.offer-carousel-restyle a[data-toggle="pill"]').on('show.bs.tab', function (e) {
      e.target // active tab
      e.relatedTarget // inactive tab
      var srcOfElementToReplace = $(e.relatedTarget).find('img');
      var srcOfOtherElementToReplace = $(e.target).find('img');
      var activeImage = $(e.relatedTarget).find('img').attr('data-active-image');
      var inactiveImage = $(e.relatedTarget).find('img').attr('data-inactive-image');
      
      if(activeImage && inactiveImage){
        srcOfElementToReplace.attr('src', srcOfElementToReplace.attr('data-inactive-image'));
        srcOfOtherElementToReplace.attr('src', srcOfOtherElementToReplace.attr('data-active-image'));
      }
    });
    
    /* const bsIcon = document.getElementById("offers-business-icon");
    const pIcon = document.getElementById("offers-private-image");

    if(bsIcon.getAttribute("data-active-image") === bsIcon.firstChild.getAttribute("src")){
      bsIcon.firstChild.getAttribute("src") = bsIcon.firstChild.getAttribute("data-inactive-image");
    } else {
      bsIcon.firstChild.getAttribute("src") = bsIcon.firstChild.getAttribute("data-active-image");
    }

    if(pIcon.firstChild.getAttribute("data-active-image") === pIcon.firstChild.getAttribute("src")){
      pIcon.firstChild.getAttribute("src") = pIcon.firstChild.getAttribute("data-inactive-image");
    } else {
      pIcon.firstChild.getAttribute("src") = pIcon.firstChild.getAttribute("data-active-image");
    } */
    
  }

  function initOfferCarousel() {
    var offerCarousel = $('.offer-carousel-restyle .tab-pane.active  .owl-carousel');
    if(!offerCarousel.length) return null;

    $.each(offerCarousel, function () {
      var owlItem = $(".item", this).length;
      var oc = $(this).owlCarousel({
        dots: false,
        nav: true,
        margin: 30,
        navText: [
          "<i class='fas fa-arrow-left'></i>",
          "<i class='fas fa-arrow-right'></i>"
        ],
        mouseDrag: false,
        onInitialized: callback,
        onResized: callback,
        responsive: {
          0: {
            items: 1,
            stagePadding: owlItem > 1 ? 15 : 10,
            singleItem: true,
            dots: false,
            center: true,
            margin: owlItem > 1 ? -45 : 0,
            loop: false,
          },
          992: {
            items: 2,
          },
          1200: {
            items: 3,
          }
        }
      });
      mainOfferCarousel.push(oc);
    });
    handleTabActiveInactiveIcon();
  }

  function callback(events) {
    if (events.item.count) {
      $(events.target).addClass('d-flex justify-content-center')
    }
    var offerCards = $('.offer-carousel-restyle .tab-pane.active  .owl-carousel .offer-card');
    fullCardResizeGeneric(offerCards);
    //applyHeightToFlipBox();
    /*var activeTabIcon = $('.offer-carousel-restyle a[data-toggle="pill"]:not(.active) img');
    activeTabIcon.attr('src', activeTabIcon.data('inactive-image'));*/
  }

  function fullCardResizeGeneric(offerCards) {
    //var offerCards = $('.offer-carousel-restyle .tab-pane.active  .owl-carousel .offer-card');
    var classToCheck = ['.offer-price-details', '.car-properties', '.offer-title', '.offer-subtitle', '.products-banner, .offer-card-cta-container', '.first-bottom-text', '.second-bottom-text', '.offer-box'];

    if($(window).width() > 767) {
      classToCheck.forEach((className) => {
        offerCards.find(className).css('height', 'auto');
        var maxElementHeight = Math.max.apply(null, offerCards.map(function ()
        {
          var elements = $(this).find(className);
          if (elements.length>1){
            var maxHeightLocal = 0;
            elements.each(function () {
              maxHeightLocal = ($(this).innerHeight()>maxHeightLocal)? $(this).innerHeight() : maxHeightLocal;
            });
            return maxHeightLocal;
          }else {
            return elements.innerHeight()
          }
        }).get());

        if (className == '.offer-box') {
          offerCards.find(className).css('min-height', maxElementHeight);
        } else {
          offerCards.find(className).css('height', maxElementHeight);
        }
        
      })
    }
  }

  function applyHeightToFlipBox() {
    var maxHeight = 0;
    $(".flip-card", offerCarousel).each(function () {
      var currentCardHeight = $(this).find('.offer-box-front .offer-box-wrapper').outerHeight();
      if (currentCardHeight > maxHeight) {
        maxHeight = $(this).find('.offer-box-front .offer-box-wrapper').outerHeight();
      }
    });

    if (initialMaxHeight !== null && maxHeight > initialMaxHeight) {
      maxHeight = initialMaxHeight + 30;
    }

    $(".flip-card", offerCarousel).each(function () {
      var backFlip = $(this).find('.offer-box-back');
      var frontFlip = $(this).find('.offer-box-front');
      var carImage = $(this).find('.car-image');
      if (carImage) {
        $(".offer-back-image-emulate", this).css({
          'height': carImage.outerHeight()-100
        })
      }
      $(this).css({
        'height': maxHeight
      });
      backFlip.css({
        'height': maxHeight
      })
      frontFlip.css({
        'height': maxHeight
      })
    });

    $(".offer-box-last-cta .offer-box", offerCarousel).css({
      'height': maxHeight
    })

    initialMaxHeight = maxHeight;
  }

  return {
    init: init,
    fullCardResizeGeneric: fullCardResizeGeneric
  };
})(jQuery);
