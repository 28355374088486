var campaignLandingManager = (function (){
    var $DOM = {};

    function init(){
        $DOM.wrapper = $(".campaign-active-offer-place");
        $DOM.activeOfferDetailsPlace = $(".campaign-active-offer-detail-place");
        $campaign = $(".campaign-section");

        if($campaign.length == 0){
            return;
        } else {
            $(document).ready( function (){
                initializeForm();
                handleSwitchActiveOffer();
                //alignBoxHeight();
                handleSwitchTarget();
                enableFormAccordion();
                handleScroll();
            })
            $(window).on("resize", function(){
                alignBoxHeight();
            })
        }
    }

    function handleSwitchTarget(){
        let selectedTargetValue = $('#select2-campaign_target-container');
        let campaignTargetSelect = $('.main-quote-request-form .userInfo-container #campaign_target');
        let offerCardsCampaign = $('.campaign-section .campaign-offers-container-place');



        if(selectedTargetValue !== undefined && selectedTargetValue !== null && !selectedTargetValue.closest('.row').attr('hidden')){
            if(campaignTargetSelect.length !== 0){
                setTimeout(
                    function()
                    {
                        let numberOfBusinessOffers = offerCardsCampaign.data('count-b2b-offers');
                        let totalNumberOfOffers = offerCardsCampaign.find('.owl-item:not(.cloned)').length;
                        let numberOfPrivateOffers = totalNumberOfOffers - numberOfBusinessOffers;
                        let offerCounter = 0;
                        offerCardsCampaign.find('.owl-item:not(.cloned)').each(function (){
                            if(offerCounter >= numberOfPrivateOffers){
                                $(this).addClass('d-none').attr('hidden', true);
                            }
                            offerCounter = offerCounter + 1;
                        });
                    }, 1000);
            }
            campaignTargetSelect.on('change', function (){
                offerCardsCampaign.find('.owl-item').each(function (){
                    if($(this).hasClass('active')){
                        $(this).removeClass('active').addClass('d-none').attr('hidden', true);
                    } else if(!$(this).hasClass('cloned')){
                        $(this).addClass('active').removeClass('d-none').attr('hidden', false);
                    }
                });
                offerCardsCampaign.find('.owl-item.active:first .campaign-single-offer-content-place').trigger('click');
            });
        }
    }

    function handleScroll(){
        $(window).on("scroll", function(){
            if($("#form-preventivo-section").hasClass("accordion-form")) return;
            if(isFormVisible()){
                if(!$(".bring-up-form").hasClass("d-none")){
                    $(".bring-up-form").addClass("d-none");
                }
                
            }else {
                if($(".bring-up-form").hasClass("d-none")){
                    $(".bring-up-form").removeClass("d-none")
                }
            };
        })
    }

    function isFormVisible(){
        if($("#form-preventivo-section").isInViewport()){
            return true;
        }
        return false;
    }

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
    
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
    
        return elementTop - 20 < viewportBottom;
    };

    function enableFormAccordion(){
        $(".bring-up-form").on("click", function(){
            if($("#form-preventivo-section").hasClass("accordion-form-move")){
                $("#form-preventivo-section").removeClass("accordion-form-move");
                setTimeout(() => {
                $("#form-preventivo-section").removeClass("accordion-form");
                $(".bring-up-form span.close-label").addClass("d-none");
                $(".bring-up-form span:not(.close-label)").removeClass("d-none");
            }, 500);
            }else {
                $("#form-preventivo-section").addClass("accordion-form");
                $(".bring-up-form span.close-label").removeClass("d-none");
                $(".bring-up-form span:not(.close-label)").addClass("d-none");
            setTimeout(() => {
                $("#form-preventivo-section").addClass("accordion-form-move");
            }, 0);
            }
            
            
        })
    }

    function  alignBoxHeight(){
        $campaignOffers = $(".campaign-single-offer");

        maxTopHeight = 0;
        maxBottomHeight = 0;

        $campaignOffers.each(function (index){
            if($(this).find(".campaign-single-offer-content-top").first().height() > maxTopHeight) maxTopHeight = $(this).find(".campaign-single-offer-content-top").first().height();
            if($(this).find(".campaign-single-offer-content-bottom").first().height() > maxBottomHeight) maxBottomHeight = $(this).find(".campaign-single-offer-content-bottom").first().height();
        })
        $campaignOffers.each(function (index){
            $(this).find(".campaign-single-offer-content-top").first().height(maxTopHeight);
            $(this).find(".campaign-single-offer-content-bottom").first().height(maxBottomHeight);
        })  

    }




    function initializeForm(){
        if($('.main-quote-request-form select[name="customer_type"]').select2().length > 0){
            if($('.main-quote-request-form select[name="customer_type"]').select2().val() === ''){
                $('.main-quote-request-form select[name="customer_type"]').select2().val('professional');
            }
            $('.main-quote-request-form select[name="customer_type"]').select2().trigger('change');
        }
    }

    function handleSwitchActiveOffer(){
        campaignOffers = $(".campaign-single-offer");
        setActiveContainer(campaignOffers, null);
        campaignOffers.click(function(){
            setActiveContainer(campaignOffers, this);
            renderActiveOffer(campaignOffers.index(this));
            updateForm(campaignOffers.index(this));
            changeBackground(campaignOffers.index(this));
        })
    }

    function changeBackground(selectedOfferIndex){
        $mainBody = $(".campaign-section");
        $mainBody.removeAttr("class");
        $mainBody.addClass("campaign-section campaign-section__"+selectedOfferIndex);
    }

    function updateForm(selectedOfferIndex, offers){
        $offerSelect = $(".main-quote-request-form select#multiple_offers");
        $offerSelect.prop("selectedIndex", selectedOfferIndex);
        $offerSelect.trigger("change");

        $promoCode = $(".main-quote-request-form input#promo_code-field");
        $promoCode.val(window.campaignOffersObject[selectedOfferIndex]["promoCode"])
    }

    function setActiveContainer(elements, clickedItem){
        elements.removeClass("active-campaign-offer");
        elements.removeAttr("style");
        if(clickedItem){
            elements.not(clickedItem).css("opacity","0.45")
            $(clickedItem).addClass("active-campaign-offer");
        } else {
            elements.not(":first").css("opacity","0.45")
            elements.first().addClass("active-campaign-offer");
        }
    }

    function renderActiveOffer(activeIndex){
        $DOM.activeOfferDetailsPlace.empty();

        offerTemplate = TPL.campaign_offer;

        var tplData = {
            item: window.campaignOffersObject[activeIndex] ,
            ENV: window.ENV
        }

        var html = Mustache.render(offerTemplate, tplData)
        $DOM.activeOfferDetailsPlace.append(html);

    }

    return {
        init:init,
        alignBoxHeight: alignBoxHeight,
        setActiveContainer: setActiveContainer,
        renderActiveOffer: renderActiveOffer,
        updateForm: updateForm,
        changeBackground: changeBackground

    }
})(jQuery);