var campaignOffersCarouselManager = (function () {
    var campaignOffersCarousel;
    var indexOffer = 0;
    var campaignOffersCarouselObj = $('.campaign-section .campaign-offers-container-place.owl-carousel');

    function init() {
        $(document).ready(function () {
            if (campaignOffersCarouselObj.length) {
                initCarousel();
            }
        });

        $( window ).resize(function () {
            if (campaignOffersCarouselObj.length) {
            }
            
        });
    }

    function setCarouselArrowsHeight(){
        var relatedBlogImg = $(".item", relatedBlogsObj).find(".single-related-blog-img img").eq(0);
        $(".related-blogs-place .owl-nav button").css({
            "top" : relatedBlogImg.height()/2
        })

    }

    function initCarousel() {
        $(".item", campaignOffersCarouselObj).each(function (k, v) {
            if ($(this).hasClass('active')){
                indexOffer = k;
            }
        });
        var campaignOffersNumber = $('.campaign-single-offer-container').length;
        campaignOffersCarousel = campaignOffersCarouselObj.owlCarousel({
            margin: 20,
            stagePadding: 15,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            loop:false,
            mouseDrag: false,
            touchDrag: false,
            pullDrag:false,
            center: false,
            responsive: {
                0: {
                    items: 1,
                    loop: campaignOffersNumber > 1,
                    nav: campaignOffersNumber > 1,
                    center: false,
                    stagePadding: 15,
                    touchDrag: true
                },
                992:{
                    items: 4,
                    loop: campaignOffersNumber > 4,
                    nav: false,
                    center: false,
                    stagePadding: 0,
                }
            },
            onChanged: callBackChange
        })

        campaignLandingManager.alignBoxHeight();
    }

    function callBackChange(event){
        let selectedTargetValue = $('#select2-campaign_target-container');
        if(event.page.index == -1) return;
        campaignOffers = $(".owl-item:not(.cloned, .d-none) .campaign-single-offer");
        let campaignOfferIndex = 0;

        if((selectedTargetValue !== undefined && selectedTargetValue !== null && !selectedTargetValue.closest('.row').attr('hidden'))){
            campaignOfferIndex = 2;
            if(event.item.index - 2 > 5){
                campaignOfferIndex = 6;
            }
        }

        campaignLandingManager.setActiveContainer(campaignOffers, campaignOffers.eq(event.item.index - 2 - campaignOfferIndex)[0]);
        campaignLandingManager.renderActiveOffer(event.item.index - 2 - campaignOfferIndex);
        campaignLandingManager.updateForm(event.item.index - 2 - campaignOfferIndex);
        campaignLandingManager.changeBackground(event.item.index - 2 - campaignOfferIndex);
    }

    return {
        init: init
    }
})(jQuery);