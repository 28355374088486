var claimRequestForm = (function () {
    var claimRequestForm = $('.claim-report-form .claim-request-form');

    function init() {
        var claimForm = $('.claim-request-form-authentication');

        $('#circumstanceType').select2({
            allowClear: true
        });

        $('#circumstanceInvolvedVehicles').select2({
            allowClear: true
        });

        $('#moving-dynamics-type').select2({
            allowClear: true
        });

        $('#parked-dynamics-type').select2({
            allowClear: true
        });

        claimForm.each(function () {

            var currentForm = $(this);

            $(this).submit(function (e) {
                e.preventDefault();
                currentForm.find('.is-invalid').removeClass('is-invalid');
                var errors = validateForm(currentForm);
                if (errors.length === 0) {
                    $.ajax({
                        url: $(this).attr('action'),
                        type: $(this).attr('method'),
                        dataType: 'json',
                        data: $(this).serialize(),
                        success: function (json) {
                            manageResponse(json, currentForm);
                        }
                    });
                } else {
                    showErrors(currentForm, errors, '');
                }
            });


        });

        if (claimRequestForm.length) {
        $(document).ready(function () {
            setCaiListener();
            setDynamicsListener();
            setOtherTextListener();
            setAttachmentsListener();
            setCounterpartsListener();
            setDamagesListener();
            setInputInvalidListener();
            setAttachmentsConstraintListener();
        })
        }
    }

    function fieldChecker() {
        let formUniqueID = $('#claim-form #claimForm form').attr('id');
        let claimForm = $('#claim-form #claimForm');
        let caiPositive = $('#claim-form #claimForm #positive-cai:checked').val();
        let caiPositiveComplaint = $('#claim-form #claimForm #positiveComplaint:checked').val();
        let dynamicsPositiveComplaint = $('#claim-form #claimForm #dynamicsPositiveAuthorities:checked').val();
        let witnessesPositive = $('#claim-form #claimForm .witnesses-form #witnesses-true:checked').val();
        let attachmentsConstraint = 0;
        let damagesKeys = $('#claim-form #claimForm #damagesKeys');
        let claimType = $('#claim-form #claimForm #claim-type').val();

        claimForm.find('input').each(function (){
            if($(this).prop('required')){
                if(!$.trim(this.value).length){
                    $(this).addClass('is-invalid');
                } else if($(this).attr('type') === "email" && !(/^[A-Za-z0-9._%"+-]+@[A-Za-z0-9.\[\]-]+\.[A-Za-z0-9\[\]]+$/.test($(this).val()))){
                    $(this).addClass('is-invalid');
                }
            }
            if($(this).attr('type') === "radio"){
                if($(this).prop('required')){
                    if(!$("input[name='" + $(this).attr("name") + "']:checked").val() && !$(this).prop('disabled')){
                        $(this).closest('div').addClass('is-invalid');
                    } else {
                        $(this).closest('div').removeClass('is-invalid');
                    }
                }
            }
        });

        claimForm.find('select').each(function () {
            if($(this).prop('required')){
                if(!$.trim($(this).find(":selected").val()).length && !$(this).prop('disabled')){
                    $(this).addClass('is-invalid');
                } else {
                    $(this).removeClass('is-invalid');
                }
            }
        });

        if($('.damages-form .selected-damages-keys').children().length === 1){
            if($('.damages-form .selected-damages-keys').find('li').hasClass('d-none') && !$('.damages-form #damages-form-no-damages').hasClass('d-none')){
                damagesKeys.removeClass('is-invalid');
                $('#claim-form #claimForm #requiredDamagesKeys').addClass('d-none');
            } else {
                damagesKeys.addClass('is-invalid');
                $('#claim-form #claimForm #requiredDamagesKeys').removeClass('d-none');
            }
        } else {
            damagesKeys.removeClass('is-invalid');
            $('#claim-form #claimForm #requiredDamagesKeys').addClass('d-none');
        }

        if(caiPositive !== undefined && caiPositive){
            attachmentsConstraint ++;
        }
        if(caiPositiveComplaint !== undefined && caiPositiveComplaint){
            attachmentsConstraint ++;
        }
        if(dynamicsPositiveComplaint !== undefined && dynamicsPositiveComplaint){
            attachmentsConstraint ++;
        }
        if(witnessesPositive !== undefined && witnessesPositive){
            attachmentsConstraint ++;
        }
        if(claimType === "FP" || claimType === "FT"){
            attachmentsConstraint ++;
        }

        if(caiPositive || caiPositiveComplaint || dynamicsPositiveComplaint || witnessesPositive || claimType === "FP" || claimType === "FT"){
            if($('.new-attachment').length < attachmentsConstraint){
                $('#claim-form #claimForm #attachmentsFile').addClass('is-invalid');
                $('#claim-form #claimForm #attachmentsField').addClass('is-invalid').css('background', '')
            }
        }

        if(damagesKeys.hasClass('is-invalid')){
            $('#claim-form #claimForm #requiredDamagesKeys').removeClass('d-none');
        }
        if($('#claim-form #claimForm .is-invalid').length === 0){
            $("#"+ formUniqueID).submit();
        } else {
            $('html, body').animate({
                scrollTop: $('#claim-form #claimForm .is-invalid:first').offset().top - 100
            }, 1000);
            if($("#"+ formUniqueID).find('button.g-recaptcha').length>0){
                grecaptcha.reset();
            }
        }
    }

    function setAttachmentsConstraintListener(){
        let attachmentsConstraintContainer = $('.claim-request-form #attachment-constraint-note small');
        let attachmentsConstraintText = attachmentsConstraintContainer.text();
        let claimType = $('#claim-form #claimForm #claim-type');
        let caiPositive = $('#claim-form #claimForm #positive-cai');
        let caiNegative = $('#claim-form #claimForm #negative-cai');
        let caiPositiveComplaint = $('#claim-form #claimForm #positiveComplaint');
        let caiNegativeComplaint = $('#claim-form #claimForm #negativeComplaint');
        let dynamicsPositiveComplaint = $('#claim-form #claimForm #dynamicsPositiveAuthorities');
        let dynamicsNegativeComplaint = $('#claim-form #claimForm #dynamicsNegativeAuthorities');
        let witnessesPositive = $('#claim-form #claimForm .witnesses-form #witnesses-true');
        let witnessesNegative = $('#claim-form #claimForm .witnesses-form #witnesses-false');

        caiPositive.on("click", function (){
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        });
        caiNegative.on("click", function (){
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        });
        caiPositiveComplaint.on("click", function (){
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        });
        caiNegativeComplaint.on("click", function (){
            $('.claim-request-form #attachmentsFile').prop('required', false).removeClass('is-invalid');
            $('.claim-request-form #attachmentsField').removeClass('is-invalid').css('background', 'white');
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        });
        dynamicsPositiveComplaint.on("click", function (){
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        });
        dynamicsNegativeComplaint.on("click", function (){
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        });
        witnessesPositive.on("click", function(){
            $('#witnesses-constraint-note').removeClass('d-none');
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        })
        witnessesNegative.on("click", function(){
            $('#witnesses-constraint-note').addClass('d-none');
            $('.claim-request-form #attachmentsFile').prop('required', false).removeClass('is-invalid');
            $('.claim-request-form #attachmentsField').removeClass('is-invalid').css('background', 'white');
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        })
        if(claimType.val() === "FT" || claimType.val() === "FP"){
            checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText);
        }
    }

    function checkAttachmentsConstraint(attachmentsConstraintContainer, attachmentsConstraintText) {
        let numberOfRequiredAttachments = 0;
        let claimType = $('#claim-form #claimForm #claim-type');
        let caiPositive = $('#claim-form #claimForm #positive-cai');
        let caiPositiveComplaint = $('#claim-form #claimForm #positiveComplaint');
        let dynamicsPositiveComplaint = $('#claim-form #claimForm #dynamicsPositiveAuthorities');
        let witnessesPositive = $('#claim-form #claimForm .witnesses-form #witnesses-true');
        let caiPositiveChecked = $('#claim-form #claimForm #positive-cai:checked');
        let caiPositiveComplaintChecked = $('#claim-form #claimForm #positiveComplaint:checked');
        let dynamicsPositiveComplaintChecked = $('#claim-form #claimForm #dynamicsPositiveAuthorities:checked');
        let witnessesPositiveChecked = $('#claim-form #claimForm .witnesses-form #witnesses-true:checked');

        if(claimType.val() === "FT" || claimType.val() === "FP"){
            numberOfRequiredAttachments ++;
        }

        let constraintArray = [caiPositive.attr('disabled') !== 'disabled' ? caiPositiveChecked.val() : 'false', caiPositiveComplaint.attr('disabled') !== 'disabled' ? caiPositiveComplaintChecked.val() : 'false', dynamicsPositiveComplaint.attr('disabled') !== 'disabled' ? dynamicsPositiveComplaintChecked.val() : 'false', witnessesPositive.attr('disabled') !== 'disabled' ? witnessesPositiveChecked.val() : 'false'];

        $.each(constraintArray, function(index, value){
            if(value === 'true'){
                numberOfRequiredAttachments ++;
            }
        });

        if(numberOfRequiredAttachments > 0){
            $('.claim-request-form #attachment-constraint-note').removeClass('d-none');
            attachmentsConstraintContainer.text(attachmentsConstraintText.replace('%s', numberOfRequiredAttachments));
        } else {
            $('.claim-request-form #attachment-constraint-note').addClass('d-none');
        }
    }

    function setInputInvalidListener(){

        $('#claim-form #claimForm').on("change", 'input', function (){
            if($.trim(this.value).length){
                $(this).removeClass('is-invalid');
            }
        });
    }

    function setDamagesListener(){
        let claimForm = $('#claim-form #claimForm');
        let frontModal = $('.damages-form #front-modal-button');
        let rearModal = $('.damages-form #rear-modal-button');
        let leftModal = $('.damages-form #left-modal-button');
        let rightModal = $('.damages-form #right-modal-button');
        let roofModal = $('.damages-form #roof-modal-button');
        let otherModal = $('.damages-form #other-modal-button');
        let selectedDamagesKeys = $('.damages-form .selected-damages-keys');
        let selectDamagesKeysToBeSent = $('.damages-form #damagesKeys');
        let damagesPositive = $('#claim-form #claimForm .damages-form #damages-true');
        let damagesNegative = $('#claim-form #claimForm .damages-form #damages-false');

        damagesPositive.on("click", function(){
            let damagesTextContainer = $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer').find('#damagesOtherText');
            claimForm.find('.claim-request-vehicle-containers').each(function (){
                $(this).removeClass('d-none');
            });
            if(damagesTextContainer.val() === $('.damages-form #damages-form-no-damages').find('strong').text()){
                damagesTextContainer.val('');
            }
            claimForm.find('#damages-form-no-damages').addClass('d-none');
            claimForm.find('#damages-form-no-damages').closest('div').removeClass('pb-4');
        });
        damagesNegative.on("click", function(){
            claimForm.find('.claim-request-vehicle-containers').each(function (){
                $(this).addClass('d-none');
            });
            $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer').find('#damagesOtherText').val(claimForm.find('#damages-form-no-damages').text());
            selectDamagesKeysToBeSent.find('option').each(function() {
                $(this).attr('selected', false);
            });
            selectedDamagesKeys.find("li").each(function (){
                if(!$(this).hasClass('d-none'))
                    $(this).remove();
            });
            claimForm.find('#damages-form-no-damages').removeClass('d-none');
            claimForm.find('#damages-form-no-damages').closest('div').addClass('pb-4');
        });

        if(frontModal.length){
            frontModal.on("click", function () {
                let frontModalList = $('.damages-form #damagesKeysModal .modal-body .damages-list-item');
                let otherModalText = $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer');

                frontModalList.children().remove();

                otherModalText.addClass('d-none');

                $('.damages-form #damagesKeysModal .modal-header .modal-title').text("Anteriore");
                $(".damages-form .damages-select [data-damages-keys='front-damages-option']").each(function () {
                    frontModalList.append("<li class=\"list-group-item\">\n" +
                        "<input class='form-check-input me-1' type='checkbox' name='" + $(this).val() + "' value='" + $(this).val() + "' aria-label='" + $(this).text() + "'" + ($(this).attr('selected') === 'selected' ? 'checked' : '') + ">" +
                        "<label for='" + $(this).val() + "'>" + $(this).text() + "</label></li>");
                });
                frontModalList.find('input:checkbox').each(function () {
                    $(this).change(function(){
                        if(this.checked){
                            selectedDamagesKeys.removeClass('d-none');
                            selectedDamagesKeys.append("<li>" + $(this).attr('aria-label') + "<span data-value='" + $(this).val() + "' class='damages_cross_icon fas fa-times fa-lg'></span></li>");
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', 'selected');
                        } else {
                            $("li:contains('" + $(this).attr('aria-label') + "')", selectedDamagesKeys).remove();
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', false);
                            if(selectedDamagesKeys.children().length === 0)
                                selectedDamagesKeys.addClass('d-none');
                        }
                    });
                });
            });

            rearModal.on("click", function () {
                let rearModalList = $('.damages-form #damagesKeysModal .modal-body .damages-list-item');
                let otherModalText = $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer');

                rearModalList.children().remove();

                otherModalText.addClass('d-none');

                $('.damages-form #damagesKeysModal .modal-header .modal-title').text("Posteriore");
                $(".damages-form .damages-select [data-damages-keys='rear-damages-option']").each(function () {
                    rearModalList.append("<li class=\"list-group-item\">\n" +
                        "<input class='form-check-input me-1' type='checkbox' name='" + $(this).val() + "' value='" + $(this).val() + "' aria-label='" + $(this).text() + "'" + ($(this).attr('selected') === 'selected' ? 'checked' : '') + ">" +
                        "<label for='" + $(this).val() + "'>" + $(this).text() + "</label></li>");
                });
                rearModalList.find('input:checkbox').each(function () {
                    $(this).change(function(){
                        if(this.checked){
                            selectedDamagesKeys.removeClass('d-none');
                            selectedDamagesKeys.append("<li>" + $(this).attr('aria-label') + "<span data-value='" + $(this).val() + "' class='damages_cross_icon fas fa-times fa-lg'></span></li>");
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', 'selected');
                        } else {
                            $("li:contains('" + $(this).attr('aria-label') + "')", selectedDamagesKeys).remove();
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', false);
                            if(selectedDamagesKeys.children().length === 0)
                                selectedDamagesKeys.addClass('d-none');
                        }
                    });
                });
            });

            leftModal.on("click", function () {
                let leftModalList = $('.damages-form #damagesKeysModal .modal-body .damages-list-item');
                let otherModalText = $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer');

                leftModalList.children().remove();

                otherModalText.addClass('d-none');

                $('.damages-form #damagesKeysModal .modal-header .modal-title').text("Lato sinistro");
                $(".damages-form .damages-select [data-damages-keys='left-damages-option']").each(function () {
                    leftModalList.append("<li class=\"list-group-item\">\n" +
                        "<input class='form-check-input me-1' type='checkbox' name='" + $(this).val() + "' value='" + $(this).val() + "' aria-label='" + $(this).text() + "'" + ($(this).attr('selected') === 'selected' ? 'checked' : '') + ">" +
                        "<label for='" + $(this).val() + "'>" + $(this).text() + "</label></li>");
                });
                leftModalList.find('input:checkbox').each(function () {
                    $(this).change(function(){
                        if(this.checked){
                            selectedDamagesKeys.removeClass('d-none');
                            selectedDamagesKeys.append("<li>" + $(this).attr('aria-label') + "<span data-value='" + $(this).val() + "' class='damages_cross_icon fas fa-times fa-lg'></span></li>");
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', 'selected');
                        } else {
                            $("li:contains('" + $(this).attr('aria-label') + "')", selectedDamagesKeys).remove();
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', false);
                            if(selectedDamagesKeys.children().length === 0)
                                selectedDamagesKeys.addClass('d-none');
                        }
                    });
                });
            });

            rightModal.on("click", function () {
                let rightModalList = $('.damages-form #damagesKeysModal .modal-body .damages-list-item');
                let otherModalText = $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer');

                rightModalList.children().remove();

                otherModalText.addClass('d-none');

                $('.damages-form #damagesKeysModal .modal-header .modal-title').text("Lato destro");
                $(".damages-form .damages-select [data-damages-keys='right-damages-option']").each(function () {
                    rightModalList.append("<li class=\"list-group-item\">\n" +
                        "<input class='form-check-input me-1' type='checkbox' name='" + $(this).val() + "' value='" + $(this).val() + "' aria-label='" + $(this).text() + "'" + ($(this).attr('selected') === 'selected' ? 'checked' : '') + ">" +
                        "<label for='" + $(this).val() + "'>" + $(this).text() + "</label></li>");
                });
                rightModalList.find('input:checkbox').each(function () {
                    $(this).change(function(){
                        if(this.checked){
                            selectedDamagesKeys.removeClass('d-none');
                            selectedDamagesKeys.append("<li>" + $(this).attr('aria-label') + "<span data-value='" + $(this).val() + "' class='damages_cross_icon fas fa-times fa-lg'></span></li>");
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', 'selected');
                        } else {
                            $("li:contains('" + $(this).attr('aria-label') + "')", selectedDamagesKeys).remove();
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', false);
                            if(selectedDamagesKeys.children().length === 0)
                                selectedDamagesKeys.addClass('d-none');
                        }
                    });
                });
            });

            roofModal.on("click", function () {
                let roofModalList = $('.damages-form #damagesKeysModal .modal-body .damages-list-item');
                let otherModalText = $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer');

                roofModalList.children().remove();

                otherModalText.addClass('d-none');

                $('.damages-form #damagesKeysModal .modal-header .modal-title').text("Tetto");
                $(".damages-form .damages-select [data-damages-keys='roof-damages-option']").each(function () {
                    roofModalList.append("<li class=\"list-group-item\">\n" +
                        "<input class='form-check-input me-1' type='checkbox' name='" + $(this).val() + "' value='" + $(this).val() + "' aria-label='" + $(this).text() + "'" + ($(this).attr('selected') === 'selected' ? 'checked' : '') + ">" +
                        "<label for='" + $(this).val() + "'>" + $(this).text() + "</label></li>");
                });
                roofModalList.find('input:checkbox').each(function () {
                    $(this).change(function(){
                        if(this.checked){
                            selectedDamagesKeys.removeClass('d-none');
                            selectedDamagesKeys.append("<li>" + $(this).attr('aria-label') + "<span data-value='" + $(this).val() + "' class='damages_cross_icon fas fa-times fa-lg'></span></li>");
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', 'selected');
                        } else {
                            $("li:contains('" + $(this).attr('aria-label') + "')", selectedDamagesKeys).remove();
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', false);
                            if(selectedDamagesKeys.children().length === 0)
                                selectedDamagesKeys.addClass('d-none');
                        }
                    });
                });
            });

            otherModal.on("click", function () {
                let otherModalList = $('.damages-form #damagesKeysModal .modal-body .damages-list-item');
                let otherModalText = $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer');

                otherModalList.children().remove();

                otherModalText.removeClass('d-none');

                $('.damages-form #damagesKeysModal .modal-header .modal-title').text("Altro");
                $(".damages-form .damages-select [data-damages-keys='other-damages-option']").each(function () {
                    otherModalList.append("<li class=\"list-group-item\">\n" +
                        "<input class='form-check-input me-1' type='checkbox' name='" + $(this).val() + "' value='" + $(this).val() + "' aria-label='" + $(this).text() + "'" + ($(this).attr('selected') === 'selected' ? 'checked' : '') + ">" +
                        "<label for='" + $(this).val() + "'>" + $(this).text() + "</label></li>");
                });
                otherModalList.find('input:checkbox').each(function () {
                    $(this).change(function(){
                        if(this.checked){
                            selectedDamagesKeys.removeClass('d-none');
                            selectedDamagesKeys.append("<li>" + $(this).attr('aria-label') + "<span data-value='" + $(this).val() + "' class='damages_cross_icon fas fa-times fa-lg'></span></li>");
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', 'selected');
                        } else {
                            $("li:contains('" + $(this).attr('aria-label') + "')", selectedDamagesKeys).remove();
                            selectDamagesKeysToBeSent.find("option[value='" + $(this).val() + "']").attr('selected', false);
                            if(selectedDamagesKeys.children().length === 0)
                                selectedDamagesKeys.addClass('d-none');
                        }
                    });
                });
                otherModalText.find('#damagesOtherText').change(function () {
                    if($(this).val()){
                        if(selectedDamagesKeys.find('.damages-other-text').length !== 0)
                            selectedDamagesKeys.find('.damages-other-text').remove();
                        selectedDamagesKeys.removeClass('d-none');
                        selectedDamagesKeys.append("<li class='damages-other-text'>" + $(this).val() + "<span class='damages_cross_icon fas fa-times fa-lg'></span></li>");
                    } else {
                        selectedDamagesKeys.find('.damages-other-text').remove();
                    }
                });
            });

            selectedDamagesKeys.on("click", "span.damages_cross_icon", function () {
                if($(this).closest("li").hasClass("damages-other-text")){
                    $('.damages-form #damagesKeysModal .modal-body .form-group #damagesTextContainer').find('#damagesOtherText').val('');
                } else {
                    selectDamagesKeysToBeSent.find("option[value='" + $(this).data('value') + "']").attr('selected', false);
                }
                $(this).closest("li").remove();
            });

            $('.damages-form #damagesKeysModal').on("hidden.bs.modal", function () {
                $(this).find('.modal-dialog .modal-body .damages-list-item').children().remove();
            });
        }
    }

    function setDynamicsListener(){
        let movingDynamics = document.getElementById('moving-dynamics');
        let parkedDynamics = document.getElementById('parked-dynamics');

        if(movingDynamics !== undefined && movingDynamics !== null){
            movingDynamics.onclick = function(){
                var tmpMovingContainer = $('#moving-dynamics-container');
                var tmpParkedContainer = $('#parked-dynamics-container');
                var tmpAuthorities = $('#dynamics-authorities-container');

                $('#dynamics-additional-info').removeClass('d-none');
                tmpMovingContainer.removeClass('d-none');
                tmpMovingContainer.prop('disabled', false);
                tmpParkedContainer.addClass('d-none');
                tmpParkedContainer.prop('disabled', true);
                $('#moving-dynamics-type').prop('disabled', false);
                $('#parked-dynamics-type').prop('disabled', true);
                $('#dynamicsPositiveAuthorities').prop('disabled', true);
                $('#dynamicsNegativeAuthorities').prop('disabled', true);
                tmpAuthorities.addClass('d-none');
                tmpAuthorities.prop('disabled', true);
                $('#moving-dynamics-type').change();
            }
        }
        if(parkedDynamics !== undefined && parkedDynamics !== null){
            parkedDynamics.onclick = function(){
                var tmpMovingContainer = $('#moving-dynamics-container');
                var tmpParkedContainer = $('#parked-dynamics-container');
                var tmpAuthorities = $('#dynamics-authorities-container');
                $('#dynamics-additional-info').removeClass('d-none');
                tmpMovingContainer.addClass('d-none');
                tmpMovingContainer.prop('disabled', true);
                tmpParkedContainer.removeClass('d-none');
                tmpParkedContainer.prop('disabled', false);
                $('#moving-dynamics-type').prop('disabled', true);
                $('#parked-dynamics-type').prop('disabled', false);
                $('#dynamicsPositiveAuthorities').prop('disabled', false);
                $('#dynamicsNegativeAuthorities').prop('disabled', false);
                tmpAuthorities.removeClass('d-none');
                tmpAuthorities.prop('disabled', false);
                $('#parked-dynamics-type').change();
            }
        }
    }

    function setCounterpartsListener(){
        var counterpartsTextTranslation = $('#claim-form #claimForm #new-counterpart-label').text();
        var counterpartsButton = $("#claim-form #claimForm #counterpartsButton");
        let involvedVehicles = $('#claim-form #claimForm #collapseCircumstance #circumstanceInvolvedVehicles');
        let addCounterpartsButton = $('#claim-form #claimForm .counterparts-form #counterpartsButton');

        if(involvedVehicles.length){
            involvedVehicles.on("change", function (e, string){
                var counterpartsForm = $('.counterparts-form #multiple-counterparts');
                var numberOfCounterparts = counterpartsForm.length;
                if($(this).val() > numberOfCounterparts && (string !== undefined ? string !== 'true' : true)){
                    for(numberOfCounterparts; $(this).val() > numberOfCounterparts; numberOfCounterparts++){
                        addCounterpartsButton.trigger("click", "involvedVehiclesTrigger");
                    }
                } else if($(this).val() < numberOfCounterparts && (string !== undefined ? string !== 'true' : true)){
                    for(numberOfCounterparts; $(this).val() < numberOfCounterparts; numberOfCounterparts--){
                        $('#claim-form #claimForm .counterparts-form .counterpart-removal').eq(-2).trigger("click", "involvedVehiclesTrigger");
                    }
                }
            });
        }

        if(counterpartsButton.length){
            counterpartsButton.on("click", function (event, string) {
                var counterpartsForm = $('.counterparts-form #multiple-counterparts');
                var numberOfCounterparts = counterpartsForm.length + 1;

                var tmpCounterparts = counterpartsForm.last().prop('outerHTML');
                counterpartsForm.last().after(tmpCounterparts);
                counterpartsForm.each(function (index) {
                    if (index + 2 === numberOfCounterparts) {
                        var currentCounterpart = $(this);
                        $(this).removeClass("d-none");
                        $(this).addClass("added-counterpart");
                        $(this).find('#new-counterpart-label').text(counterpartsTextTranslation + numberOfCounterparts);
                        $('#claimForm .claim-request-form .counterparts-form .counterparts-data .counterpart-input').each(function (index) {
                            var tmpHTML = $(this).prop('outerHTML');
                            currentCounterpart.find('#counterparts-tbc').last().append(tmpHTML);
                            currentCounterpart.find('input').val('');
                        });
                    }
                });
                if(string !== "involvedVehiclesTrigger"){
                    $('#claim-form #claimForm #collapseCircumstance #circumstanceInvolvedVehicles').val(numberOfCounterparts).change();
                }
                counterpartsForm.each(function (index) {
                    $(this).find('.counterpart-input').each(function () {
                        $(this).find('label').attr('for', $.isNumeric($(this).find('label').attr('for').slice(-1)) ? $(this).find('label').attr('for').slice(0, -1) + index : $(this).find('label').attr('for') + index);
                        $(this).find('input').attr('id', $.isNumeric($(this).find('input').attr('id').slice(-1)) ? $(this).find('input').attr('id').slice(0, -1) + index : $(this).find('input').attr('id') + index);
                        $(this).find('input').attr('name', $.isNumeric($(this).find('input').attr('name').slice(-1)) ? $(this).find('input').attr('name').slice(0, -1) + '' + index : $(this).find('input').attr('name') + index);
                    });
                    $(this).find('.counterpart-removal').attr('id', $.isNumeric($(this).find('button').attr('id').slice(-1)) ? $(this).find('button').attr('id').slice(0, -1) + index : $(this).find('button').attr('id') + index);
                });
            });

            $("#claim-form #claimForm .counterparts-form").on("click", "button.counterpart-removal", function (event, string) {
                $(this).closest('.added-counterpart').remove();
                var counterpartsForm = $('.counterparts-form #multiple-counterparts');
                var numberOfCounterparts = counterpartsForm.length;
                counterpartsForm.each(function (index) {
                    $(this).find('#new-counterpart-label').text(counterpartsTextTranslation + (index + 2));
                    $(this).find('.counterpart-input').each(function () {
                        $(this).find('label').attr('for', $(this).find('label').attr('for').slice(0, -1) + index);
                        $(this).find('input').attr('id', $(this).find('input').attr('id').slice(0, -1) + index);
                        $(this).find('input').attr('name', $(this).find('input').attr('name').slice(0, -1) + '' + index);
                    });
                    $(this).find('.counterpart-removal').attr('id', $.isNumeric($(this).find('button').attr('id').slice(-1)) ? $(this).find('button').attr('id').slice(0, -1) + index : $(this).find('button').attr('id') + index);
                });
                if(string !== "involvedVehiclesTrigger") {
                    $('#claim-form #claimForm #collapseCircumstance #circumstanceInvolvedVehicles').val(numberOfCounterparts).trigger("change", 'true');
                }
            });
        }
    }

    function setAttachmentsListener(){

        var storedFiles = [];
        var attachmentsButton = $("#attachmentsButton");
        var allowedFileTypes = ['jpeg', 'jpg', 'png', 'pdf'];

        if(attachmentsButton.length){
            attachmentsButton.on("click", function () {
                $('#attachmentsField').removeClass('is-invalid').css('background', 'white');
                $('#attachmentsFile').removeClass('is-invalid');
                $("#attachmentsFile").trigger("click");
            });

            $('input[type="file"]').on("change", function () {
                if(this.files[0] !== undefined){
                    if(this.files[0].size > 16777216){
                        alert("Dimensione massima file superata!");
                        this.value = "";
                    } else if($.inArray(this.files[0].type.split('/').pop(), allowedFileTypes) === -1){
                        alert("Allegare un file nel seguente formato: " + allowedFileTypes.join(', '));
                        this.value = "";
                    } else {
                        var numberOfAttachments = $('.new-attachment').length;
                        let dt = new DataTransfer();
                        $('.attachments-form').append("\
                <div class='d-flex'><input class='new-attachment' id='attachmentsField" + numberOfAttachments + "' name='attachments' placeholder='" + $(this).val().split('\\').pop() + "' type='text' disabled>" +
                            "\<span id='attachmentsField" + numberOfAttachments + "' class='cross_icon fas fa-times fa-lg' style='color: #c20e1a'></span></div>");
                        storedFiles.push($(this).prop('files'));
                        storedFiles.forEach((file) => {
                            dt.items.add(file[0]);
                        });
                        document.getElementById('attachmentsFile').files = dt.files;
                    }
                }
            });

            $(".attachments-form").on("click", "span.cross_icon", function () {
                let dt = new DataTransfer();
                storedFiles.splice($('.cross_icon').index($(this)), 1);
                storedFiles.forEach((file) => {
                    dt.items.add(file[0]);
                });
                document.getElementById('attachmentsFile').files = dt.files;
                $(this).parent().remove();
            });
        }
    }

    function setOtherTextListener(){
        let selectedCaiValue = document.getElementById('select2-circumstanceType-container');
        let selectedMovingValue = document.getElementById('select2-moving-dynamics-type-container');
        let selectedParkedValue = document.getElementById('select2-parked-dynamics-type-container');

        if(selectedCaiValue !== undefined && selectedCaiValue !== null){
            $('#circumstanceType').on('change', function (){
                if($('#circumstanceType').val() === "altro"){
                    $('#circumstanceTextContainer').removeClass('d-none');
                    $('#circumstanceOtherText').prop('disabled', false);
                } else {
                    $('#circumstanceTextContainer').addClass('d-none');
                    $('#circumstanceOtherText').prop('disabled', true);
                }
            });
        }

        if(selectedMovingValue !== undefined && selectedMovingValue !== null){
            $('#moving-dynamics-type').on('change', function (){
                if($('#moving-dynamics-type').val() === "altro"){
                    $('#dynamicsTextContainer').removeClass('d-none');
                    $('#dynamicsOtherText').prop('disabled', false);
                } else {
                    $('#dynamicsTextContainer').addClass('d-none');
                    $('#dynamicsOtherText').prop('disabled', true);
                }
            });
        }

        if(selectedParkedValue !== undefined && selectedParkedValue !== null){
            $('#parked-dynamics-type').on('change', function (){
                if($('#parked-dynamics-type').val() === "altro"){
                    $('#dynamicsTextContainer').removeClass('d-none');
                    $('#dynamicsOtherText').prop('disabled', false);
                } else {
                    $('#dynamicsTextContainer').addClass('d-none');
                    $('#dynamicsOtherText').prop('disabled', true);
                }
            });
        }
    }

    function setCaiListener() {
        let negativeCai = document.getElementById('negative-cai');
        let positiveCai = document.getElementById('positive-cai');
        if(negativeCai !== undefined && negativeCai !== null){
            negativeCai.onclick = function(){
                $('#cai-additional-info').removeClass('d-none');
                $('#circumstanceType').prop('disabled', false);
                $('#circumstanceInvolvedVehicles').prop('disabled', false);
                $('#positiveComplaint').prop('disabled', false);
                $('#negativeComplaint').prop('disabled', false);
                $('#positiveInjured').prop('disabled', false);
                $('#negativeInjured').prop('disabled', false);
                $('.claim-request-form #attachmentsFile').prop('required', false).removeClass('is-invalid');
                $('.claim-request-form #attachmentsField').removeClass('is-invalid').css('background', 'white');
            }
        }
        if(positiveCai !== undefined && positiveCai !== null){
            positiveCai.onclick = function(){
                $('#cai-additional-info').addClass('d-none');
                $('#circumstanceType').prop('disabled', true);
                $('#circumstanceInvolvedVehicles').prop('disabled', true);
                $('#positiveComplaint').prop('disabled', true);
                $('#negativeComplaint').prop('disabled', true);
                $('#positiveInjured').prop('disabled', true);
                $('#negativeInjured').prop('disabled', true);
                $('.claim-request-form #attachmentsFile').prop('required', true);
            }
        }
    }

    function validateForm(form){
        var errors = [];
        var formFieldsTypes = ['select.form-control','textarea.form-control','input.form-control[type=text]',
            'input.form-control[type=number]','input.form-control[type=email]', 'input.form-control[type=date]'];


        formFieldsTypes.forEach((formField) => {
            form.find(formField).each(function () {
                var currentElement = $(this);
                if (currentElement.data('validate-required')===true){
                    var value = currentElement.val();
                    if(value===''){
                        errors.push(currentElement.attr('name'));
                    }else if (currentElement.attr('type')==='email' && !isValidEmail(value)){
                        errors.push(currentElement.attr('name'));
                    }
                }
            });
        })

        return errors;
    }

    function showErrors(form, errors, message){
        var feedbackMessage = form.find('p.feedback-message');
        if (feedbackMessage.length > 0) {
            feedbackMessage.remove()
        }
        if (message===''){
            message = form.find('input#error_message[type=hidden]').val();
        }
        form.append('<p class="mt-2 err feedback-message">' + message + '</p>');

        if(errors.length>0){
            errors.forEach((element) => {
                form.find('#'+element+'-field').addClass('is-invalid');
            });
        }

        var header = $(".nav-main");
        var nav = $(".nav-top");
        var extraHeight = 0;
        if (header.length && nav.length) {
            extraHeight = header.outerHeight()+nav.outerHeight();
        }
        $('html, body').animate({
            scrollTop: form.offset().top-extraHeight
        }, 1500);

        if (form.find('button.g-recaptcha').length>0){
            grecaptcha.reset();
        }
    }

    function manageResponse(json, form) {
        if (json.success) {
            if (form.data('thank-you-page-type') === 'in-page'){
                form.parents("section").addClass("d-none");

                $('#'+form.attr('id')+'-thankYou-section').removeClass('d-none');
            }else if (form.data('thank-you-page-type') === 'redirect'){
                window.location.replace(form.data('thank-you-page'));
            }
        } else {
            var errors = json.errors;
            if (typeof errors !== 'undefined'){
                errors = Object.keys(errors);
            }else {
                errors = [];
            }
            showErrors(form, errors, json.message)
        }
        try{
            grecaptcha.reset();
        } catch { }
    }

    return {
        init: init,
        fieldChecker: fieldChecker
    };
})(jQuery);