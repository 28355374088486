var objectListingManager = (function () {

   
    var carousel = $('.object-listing-component .owl-carousel') 
    var cardItems = carousel.find('.card-container').length;

    function init(){
        $(document).ready( function(){
            if($ ('.object-listing-component').length <= 0){
                return;
            }
            else{
                $('.object-listing-component').each(function(){
                    var occurences = 0;
                    var item = ''
                    occurences = $(this).find('.item').length;
                    console.log(occurences);
                    item = $(this).find('.item')[0];
                    var arr = $(item).attr('class').split(" ");
                    startCarousel(occurences,arr[1]);
                })
            }
            
        })
    }

    function startCarousel(occurences,item){
        $('.object-listing-component .owl-carousel'+' .'+item).closest('.object-listing-component .owl-carousel').owlCarousel({
            navText: [
                "<i class='fas fa-arrow-left'></i>",
                "<i class='fas fa-arrow-right'></i>"
            ],
            dots: false,
            center: true,
            loop: false,
            onInitialized: callback,
            onResized: callback,
            responsive:{
                0:{
                    mouseDrag: true,
                    items: 1,
                    stagePadding: 17,
                    singleItem: true,
                    dots: false,
                    center: false,
                    loop: false,
                    nav: false,
                },
                768:{
                    mouseDrag: true,
                    items: 2,
                    stagePadding: 17,
                    singleItem: true,
                    dots: false,
                    center: false,
                    loop: false,
                    nav: false,

                },
                992:{
                    mouseDrag: false,
                    items: 3,
                    stagePadding: 0,
                    singleItem: false,
                    dots: false,
                    center: false,
                    loop: false,
                    nav: occurences > 3,
                }
            }
        });
        console.log(item);
    }

    function callback(events) {
        var offerCards = $('.object-listing-component .owl-carousel .offer-card');
        offersCarouselManager.fullCardResizeGeneric(offerCards);
    }

  return {init: init}

})(jQuery);